import React from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  Div,
  Paragraph,
  Span,
  Strong,
  Li,
  Ul,
  Link,
  H6,
  H5,
  H4,
  H3,
  H2,
  H1,
  MessageMarkdownContent,
  Details,
  Summary,
  Ol,
} from '../style';
import rehypeRaw from 'rehype-raw';

const propTypes = {
  isDark: PropTypes.bool,
  children: PropTypes.string,
  options: PropTypes.object,
};

export const MessageMarkdown = ({ children, options, ...rest }) => {
  return (
    <MessageMarkdownContent>
      <Markdown
        {...options}
        components={{
          style: Span,
          script: Span,
          // eslint-disable-next-line
          a: ({ node, ...props }) => <Link target="_blank" {...props} />,
          ul: Ul,
          ol: Ol,
          li: Li,
          h6: H6,
          h5: H5,
          h4: H4,
          h3: H3,
          h2: H2,
          h1: H1,
          div: Div,
          span: Span,
          p: Paragraph,
          strong: Strong,
          details: Details,
          summary: Summary,
          ...options?.components,
        }}
        rehypePlugins={[rehypeRaw]}
        {...rest}
      >
        {children}
      </Markdown>
    </MessageMarkdownContent>
  );
};
MessageMarkdown.propTypes = propTypes;

const LINK_REGEX = /\[.*?]\((https?:\/\/[^)]+)\)/g;

export function addReferenceToLinks(message, linkFormatter = withLinkIndex) {
  let linkIndex = 1;
  const links = [];

  const msg = message.replace(LINK_REGEX, (match) => {
    const link = match.match(/\((https?:\/\/[^)]+)\)/)?.[1] ?? '';

    if (!isValidUrl(link)) {
      return match;
    }

    if (isImage(link)) {
      return match;
    }

    links.push(new URL(link));
    const numberedLink = linkFormatter(match, linkIndex);

    linkIndex++;
    return numberedLink;
  });

  return { message: msg, links, hasLinks: links.length > 0 };
}

export function isValidUrl(string) {
  if (!string) return false;
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export function isImage(url) {
  return /\.(jpeg|jpg|gif|png|webp|bmp|svg)(\?.*)?$/i.test(url);
}

export function withLinkIndex(link, index) {
  return `${link} <span style="width: 18px; height: 18px; border-radius: 4px; background-color: #fff; display: inline-flex; justify-content: center; align-items: center; margin-left: 4px; font-size: 12px; color: #61616A; font-weight: 400;">${index}</span>`;
}
