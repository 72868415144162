import React from 'react';
import PropTypes from 'prop-types';
import { avatarSize } from 'components/UserAvatar';
import Button from 'components/Button';

import {
  MessageWrapper,
  ContentWrapper,
  MessageAnswersWrapper,
  MessageMarkdownWrapper,
} from '../style';
import AIAvatar from 'components/AIAvatar';
import { addReferenceToLinks, MessageMarkdown } from './MessageMarkdown';
import { EmbedLinksList } from './MessageLinks';

const propTypes = {
  message: PropTypes.object,
  isOutcoming: PropTypes.bool,
  sendHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const MessageWithAnswers = ({
  message: { text, options },
  isOutcoming,
  sendHandler,
  disabled,
}) => {
  const { message: finalMessage, links } = addReferenceToLinks(text);
  return (
    <MessageWrapper isOutcoming={isOutcoming}>
      <AIAvatar size={avatarSize.MEDIUM} />

      <ContentWrapper isOutcoming={isOutcoming}>
        <MessageMarkdownWrapper isOutcoming={isOutcoming}>
          <MessageMarkdown>{finalMessage}</MessageMarkdown>

          <EmbedLinksList links={links} />
        </MessageMarkdownWrapper>
        {!isOutcoming && (
          <MessageAnswersWrapper>
            {options.map((option, i) => (
              <Button
                key={i}
                secondary
                isSmall
                disabled={disabled}
                onClick={() => {
                  sendHandler(option);
                }}
              >
                {option}
              </Button>
            ))}
          </MessageAnswersWrapper>
        )}
      </ContentWrapper>
    </MessageWrapper>
  );
};

MessageWithAnswers.propTypes = propTypes;

export default MessageWithAnswers;
