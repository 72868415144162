/* stylelint-disable */
import styled, { keyframes } from 'styled-components';
import { rem, rgba } from 'polished';

import { breakpoints, colorName, colors, font, variables } from 'styles';
import { inputStyles } from 'components/Input/style';
import { StyledTextarea } from 'components/Textarea/style';

const ICON_SIZE = 18;
const ICON_SPACING = 8;
const MESSAGE_WIDTH = 450;
const CHAT_HEIGHT = 450;

export const ChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${rem(CHAT_HEIGHT)};
  margin: 0 0 ${rem(16)} 0;
  padding: ${rem(16)} 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${rem(variables.borderRadius)};

  &:after {
    position: absolute;
    top: ${rem(8)};
    left: 0;
    width: 100%;
    height: ${rem(24)};
    background: ${({ backgroundColor }) =>
      `linear-gradient(to bottom, ${rgba(backgroundColor, 1)} 30%, ${rgba(
        backgroundColor,
        0
      )} 100%)`};
    content: '';
    pointer-events: none;
  }

  @media ${breakpoints.tablet} {
    margin: 0 0 ${rem(32)} 0;
    padding: ${rem(12)} 0 0 0;
  }
`;

export const WelcomeMessageWrapper = styled.div`
  margin-bottom: ${rem(22)};
  padding: ${rem(10)} ${rem(12)};
  font-size: ${rem(font.size.small)};
  line-height: ${rem(22)};
  background: ${colors.lightGray};
  border-radius: ${rem(8)};
`;

export const NoMessagesWrapper = styled.div`
  color: ${colors.mediumGray};
  font-size: ${rem(font.size.small)};
  text-align: center;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding: ${rem(24)} ${rem(20)} 0 0;
  overflow-y: scroll;
`;

export const LoadMoreButton = styled.button`
  margin: ${rem(16)} auto ${rem(16)} auto;
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: ${rem(font.size.tiny)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled }) => (disabled ? colors.gray : colors.darkGray)};
`;

export const MessageAnswersWrapper = styled.div`
  display: flex;
  gap: ${rem(4)};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isOutcoming }) => (isOutcoming ? 'row-reverse' : 'row')};
  margin: 0 0 ${rem(16)} ${rem(12)};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isOutcoming }) => (isOutcoming ? 'flex-end' : 'flex-start')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin: ${({ isOutcoming }) => (isOutcoming ? `0 ${rem(8)} 0 0` : `0 0 0 ${rem(8)}`)};
`;

export const Time = styled.span`
  color: ${colors.darkGray};
  font-size: ${rem(font.size.tiny)};
`;

export const ChatMessageAuthor = styled.span`
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};
`;

export const Input = styled.textarea`
  ${inputStyles};
  padding: ${rem(12)} ${rem(2 * ICON_SIZE + 4 * ICON_SPACING)} ${rem(12)} ${rem(24)};
`;

export const InputWrapper = styled.form`
  position: relative;
`;

export const FileLoader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${rem(4)};
`;

export const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: ${({ multiline }) => (multiline ? 'end' : 'center')};
  height: 100%;
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.secondary)};
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${rem(ICON_SPACING)};
  color: inherit;
  background: none;
  border: 0;
  outline: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:last-child {
    margin: 0 ${rem(ICON_SPACING)} 0 0;
  }

  & > svg {
    width: ${rem(ICON_SIZE)};
    height: ${rem(ICON_SIZE)};
  }

  &:disabled {
    color: ${colors.gray};
  }
`;

export const ChatAnonymizeTextArea = styled(StyledTextarea)`
  height: ${rem(56)};
  margin: 0 0 ${rem(8)} 0;
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
`;

export const FinishThreadWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  justify-content: center;
  margin: ${rem(24)} 0;
  padding: 0 ${rem(12)};
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};

  & span {
    font-weight: ${font.weight.bold};
  }
`;

export const ThreadWillEndWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  justify-content: center;
  padding: 0 ${rem(12)};
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};

  & span {
    font-weight: ${font.weight.bold};
  }
`;

export const LockIconWrapper = styled.div`
  width: ${rem(32)};
  height: ${rem(32)};
  padding: ${rem(6)};
  color: black;
  background: ${colors.athensGray};
  border-radius: ${rem(12)};
`;

export const JoinThreadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 16px var(--border-radius-rounded-none, 0px);
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};
  background: ${colors.lightGray};
  border-radius: 8px;
`;

export const ThreadDividerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: ${rem(48)} 0;
  border-bottom: 1px solid ${colors.gray};
`;

export const ThreadDividerBoxWrapper = styled.div`
  position: absolute;
  left: 50%;
  display: block;
  overflow: hidden;
  color: ${colors.mediumGray};
  background: white;
  transform: translate(-50%, -50%);
`;

export const ThreadDividerBox = styled.span`
  position: relative;
  display: block;
  margin-right: 0.8em;
  margin-left: 0.8em;
  padding: 2px 10px;
  font-size: ${rem(font.size.tiny)};
  white-space: nowrap;
  text-decoration: center;
  border-color: ${colors.gray};
  border-style: solid;
  border-width: 1px 0;
  transition: border-color 500ms ease, color 250ms ease;

  &:before {
    position: absolute;
    top: -50%;
    right: 0;
    bottom: 50%;
    left: -0.8em;
    display: inline-block;
    border-color: ${colors.gray} !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    content: '';
  }

  &:after {
    position: absolute;
    top: -50%;
    right: -0.8em;
    bottom: 50%;
    left: 0;
    display: inline-block;
    border-color: ${colors.gray} !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: rotate(-45deg);
    transform-origin: 100% 100%;
    content: '';
  }

  &:before,
  &:after {
    border-color: white;
    border-style: solid;
    border-width: 0;
  }
`;

const BounceAnimation = keyframes`
  0% { transform: translate3d(0, 0,0); }
  50% {transform: translate3d(0, 5px,0);}
  100% { transform: translate3d(0, 0,0); }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background-color: ${colors.darkGray};
  border-radius: 50%;
  animation: ${BounceAnimation} 0.7s ease-in infinite;
  animation-delay: ${(props) => props.delay};
`;

export const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const Text = styled.div`
  width: auto;
  margin: 0 0 ${rem(8)} 0;
  padding: ${rem(12)} ${rem(24)};
  color: ${colorName.black};
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-world;
  background-color: ${colors.lighterGray};
  border-radius: ${rem(variables.borderRadius)}
    ${({ isDark, isOutcoming }) =>
      isDark &&
      !isOutcoming &&
      `
    background: ${colors.yellow};
  `};

  @media ${breakpoints.tablet} {
    max-width: ${rem(MESSAGE_WIDTH)};
    padding: ${rem(12)} ${rem(16)};
  }
`;

export const File = styled(Text)`
  display: flex;
  align-items: center;
  padding: ${rem(12)} ${rem(24)};
  color: ${colorName.secondary};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.tiny)};
  text-decoration: none;
  word-break: break-all;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  & > svg {
    width: ${rem(ICON_SIZE / 2)};
    height: ${rem(ICON_SIZE / 2)};
    margin: 0 ${rem(4)} 0 0;
  }
`;

export const MessageMarkdownWrapper = styled.div`
  width: auto;
  margin: 0 0 ${rem(8)} 0;
  padding: ${rem(12)} ${rem(24)};
  background-color: ${colors.lighterGray};
  border-radius: ${rem(variables.borderRadius)}
    ${({ isDark, isOutcoming }) =>
      isDark &&
      !isOutcoming &&
      `
    background: ${colors.yellow};
  `};

  @media ${breakpoints.tablet} {
    max-width: ${rem(MESSAGE_WIDTH)};
    padding: ${rem(12)} ${rem(16)};
  }
`;

export const MessageMarkdownContent = styled.div`
  color: ${colors.darkestGray};
  font-weight: normal;
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
`;

export const Details = styled.details`
  color: ${colors.darkestGray};
  font-size: ${rem(font.size.small)};
  line-height: 3;
  white-space: initial;
  word-break: break-word;
`;

export const Summary = styled.summary`
  color: ${colors.darkestGray};
  font-size: ${rem(font.size.small)};
  line-height: 2.5;
  white-space: initial;
  word-break: break-word;
`;

export const Strong = styled.strong`
  color: ${colors.darkestGray};
  font-weight: bold;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Paragraph = styled.p`
  color: ${colors.darkestGray};
  font-weight: normal;
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(2)} 0 ${rem(2)} 0;
`;

export const Span = styled.span`
  color: ${colors.darkestGray};
  font-weight: normal;
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Link = styled.a`
  color: ${colors.darkestGray};
  font-weight: 600;
  font-size: ${rem(font.size.small)};
  text-decoration: none;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  &:hover {
    text-decoration: underline;
  }
`;

export const Div = styled.div`
  color: ${colors.darkestGray};
  font-weight: normal;
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(2)} 0 ${rem(2)} 0;
`;

export const Ul = styled.ul`
  padding: 0 0 0 20px;
  margin: ${rem(8)} 0 ${rem(8)} 0;
  white-space: normal;
  word-break: normal;
`;

export const Ol = styled.ol`
  padding: 0 0 0 20px;
  margin: ${rem(8)} 0 ${rem(8)} 0;
  white-space: normal;
  word-break: normal;
`;

export const Li = styled.li`
  color: ${colors.darkestGray};
  font-weight: normal;
  font-size: ${rem(font.size.small)};
  white-space: normal;
  word-break: break-word;
  margin: 0;
`;

export const H1 = styled.h1`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.bigger)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(16)} 0 ${rem(10)} 0;
`;

export const H2 = styled.h2`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.big)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(14)} 0 ${rem(8)} 0;
`;

export const H3 = styled.h3`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.medium)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(12)} 0 ${rem(6)} 0;
`;

export const H4 = styled.h4`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.normal)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(8)} 0 ${rem(4)} 0;
`;

export const H5 = styled.h5`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.small)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(4)} 0 ${rem(4)} 0;
`;

export const H6 = styled.h6`
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: ${rem(font.size.tiny)};
  white-space: pre-wrap;
  word-break: break-word;
  margin: ${rem(2)} 0 ${rem(2)} 0;
`;

export const EmbedLinksWrapper = styled.div`
  display: flex;
  gap: ${rem(4)};
  width: 100%;
  margin-top: 10px;
`;

export const EmbedLinksHeading = styled.p`
  font-size: ${rem(font.size.tiny)};
  margin: ${rem(6)} 0 0 0;
  color: ${colors.darkGray};
  flex-shrink: 0;
`;

export const EmbedList = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  gap: ${rem(4)};
  flex-wrap: wrap;
  margin: ${rem(2)} 0 0 0;
`;

export const EmbedListItem = styled.li`
  margin: 0;
  border-radius: ${rem(variables.borderRadius)};
  border: 1px solid ${colors.grayish};
  font-size: ${rem(font.size.tiny)};
  padding: 0;
  overflow: hidden;
`;

export const EmbedListItemHostname = styled.span`
  color: ${colors.darkBlue};
  padding: 2px 4px 2px 0;
`;

export const EmbedListItemLink = styled.a`
  gap: 2px;
  display: flex;
  align-items: center;
  padding: 0;
  color: ${colors.darkGray};
  text-decoration: none;

  &:hover ${EmbedListItemHostname} {
    text-decoration: underline;
  }
`;

export const EmbedListItemIdx = styled.span`
  color: ${colors.darkGray};
  display: block;
  width: 20px;
  text-align: center;
  background: ${colors.white};
  padding: 2px 4px;
  min-width: fit-content();
  text-decoration: none !important;
`;
