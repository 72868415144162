import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  loadMoreMessages: {
    id: 'Chat.loadMoreMessages',
    defaultMessage: 'Load more',
    description: 'Chat load more button',
  },
  noMoreMessages: {
    id: 'Chat.noMoreMessages',
    defaultMessage: 'No more messages',
    description: 'Chat load more not found',
  },
  header: {
    id: 'FloatingChat.header',
    defaultMessage: 'Chat with Compliance Officer',
    description: 'Support Chat header',
  },
  escalateToCO: {
    id: 'FloatingChat.escalateToCO',
    defaultMessage: 'Escalate to CO',
    description: 'Support Chat escalate to CO button text',
  },
  finishThread: {
    id: 'FloatingChat.finishThread',
    defaultMessage: 'Finish thread',
    description: 'Support Chat finish thread button text',
  },
});
