import React from 'react';

function MisconbotIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1721 2.43018L7.88837 5.90861C5.74884 6.70421 4 9.22053 4 11.4778V25.225C4 27.4083 5.45116 30.2762 7.2186 31.5898L15.2186 37.5291C17.8419 39.4903 22.1581 39.4903 24.7814 37.5291L32.7814 31.5898C34.5488 30.2762 36 27.4083 36 25.225V11.4778C36 9.20202 34.2512 6.68571 32.1116 5.89011L22.8279 2.43018C21.2465 1.85661 18.7163 1.85661 17.1721 2.43018Z"
        fill="#FFE600"
        stroke="#747480"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1211 12.2941V12.7941V13.2941C12.5282 13.2941 13.0761 13.5659 13.5483 14.0381C14.0204 14.5102 14.2922 15.0581 14.2922 15.4652H14.7922H15.2922C15.2922 15.0581 15.564 14.5102 16.0361 14.0381C16.5083 13.5659 17.0561 13.2941 17.4633 13.2941V12.7941V12.2941C17.0561 12.2941 16.5083 12.0224 16.0361 11.5502C15.564 11.0781 15.2922 10.5302 15.2922 10.123H14.7922H14.2922C14.2922 10.5302 14.0204 11.0781 13.5483 11.5502C13.0761 12.0224 12.5282 12.2941 12.1211 12.2941ZM14 17.7V18V18.3C13.6674 18.3 13.2394 18.5182 12.8788 18.8788C12.5182 19.2394 12.3 19.6674 12.3 20H12H11.7C11.7 19.6674 11.4818 19.2394 11.1212 18.8788C10.7606 18.5182 10.3326 18.3 10 18.3V18V17.7C10.3326 17.7 10.7606 17.4818 11.1212 17.1212C11.4818 16.7606 11.7 16.3326 11.7 16H12H12.3C12.3 16.3326 12.5182 16.7606 12.8788 17.1212C13.2394 17.4818 13.6674 17.7 14 17.7ZM21.0573 25.6016L20.364 23.3254H16.8779L16.1846 25.6016H14L17.3749 16H19.8538L23.2418 25.6016H21.0573ZM19.88 21.6249C19.239 19.5624 18.8771 18.396 18.7942 18.1257C18.7158 17.8553 18.6591 17.6417 18.6242 17.4847C18.4803 18.0428 18.0682 19.4229 17.388 21.6249H19.88ZM28.0292 25.6014H23.6797V24.4502L24.8439 23.9139V17.7265L23.6797 17.1902V16.0391H28.0292V17.1902L26.8649 17.7265V23.9139L28.0292 24.4502V25.6014Z"
        fill="#2E2E38"
      />
    </svg>
  );
}

export default MisconbotIcon;
