import { rem } from 'polished';

export const colors = {
  white: '#ffffff', // DS: Surface, White & Light
  black: '#000000', // DS: Black
  mako: '#41424b', // DS: Gray 6
  yellow: '#ffe600', // DS: Primary
  darkYellow: '#ffc700', // DS: Orange 2
  brownYellow: '#58532d', // DS: Yellow 2
  lightViolet: '#b3437f', // DS: Maroon 2
  violet: '#750E61',
  maroonLight: '#f1e7ef', // DS: Maroon Light
  darkestGray: '#2e2e38', // DS: EY Secondary
  misconBotGray: '#24242E', // DS: EY Light
  misconInput: '#1B1B25',
  darkGray: '#747480', // DS: Gray 2
  mediumGray: '#a2a2a8', // DS: Gray 3
  gray: '#c4c4cc', // DS: Gray 4
  grayish: '#e4e4ed', // DS: Gray 5
  lightGray: '#f5f5fa', // DS: White 1
  athensGray: '#ebebed', // DS: White 2
  lighterGray: '#fafafc', // DS: Dark
  darkBlue: '#188ce5', // DS: Neutral
  lightBlue: '#e8f4fd', // DS: Blue Light
  green: '#168736', // DS: Success
  lightGreen: '#e0f0f1', // DS: Light Green 2 - Alert BG
  successGreen: '#e8fded',
  orange: '#ff9831', // DS: Warning
  darkestRed: '#b40a15', // DS: Required star
  darkRed: '#b9251c', // DS: EY Error
  misconRed: '#FF736A',
  reddish: '#ffeceb', // DS: Light Red 2 - White BG
  lightRed: '#f6e6e6', // DS: Light Red 1 - Alert BG
  darkestBlue: '#155cb4', // DS: EY Blue 06
  disabledGray: '#eaeaf2', // DS: EY Disabled
  whisper: '#f6f6fa', // DS: EY Grey 4
  shark: '#212528', // DS: EY Progress Tick
  mischka: '#e1e1e6', // DS: EY Menu Hover
  frenchGray: '#c4c4cd', // DS: EY Utility Button
  navy: '#336699',
  lightNavy: '#e0e8f0',
  aiMain: '#0BA581',
};

export const colorName = {
  white: colors.white,
  black: colors.black,
  primary: colors.yellow,
  secondary: colors.darkestGray,
  neutral: colors.darkBlue,
  disabled: colors.mediumGray,
  success: colors.green,
  warning: colors.orange,
  info: colors.darkGray,
  error: colors.darkRed,
  errorBackground: colors.reddish,
  separator: colors.grayish,
  mainBackground: colors.lighterGray,
  sidebarBackground: colors.darkestGray,
  link: colors.darkestBlue,
  confidentGrey: colors.darkestGray,
  togglePrimary: colors.navy,
  toggleSecondary: colors.lightNavy,
};

export const transitions = {
  spring: 'cubic-bezier(.17, .67, .47, 1)',
  uiTiming: '0.3s',
  ease: 'ease',
};

export const font = {
  family: 'Noto Sans, sans-serif',
  size: {
    tiny: 12,
    small: 14,
    normal: 16,
    medium: 18,
    big: 22,
    bigger: 24,
    large: 26,
    huge: 32,
    gigantic: 48,
    enormous: 80,
  },
  weight: {
    regular: 400,
    bold: 700,
  },
};

export const globalSize = {
  leftColumnMaxWidth: 1240,
  leftColumnContentWidth: 992,
  rightColumnContentWidth: 328,
  rightColumnContentWidthWider: 440,
  defaultPadding: 16,
  mobileMenuSize: 60,
  desktopMenuSize: 72,
  expandedSideMenuWidth: 152,
  mobileInputWidth: 360,
  fileItemHeight: 40,
};

export const variables = {
  borderRadius: 4,
  borderRadiusLarge: 16,
};

export const layers = {
  top: 40,
  medium: 30,
  low: 20,
  lowest: 10,
  zero: 0,
  negative: -1,
};

export const deviceSizes = {
  mobile: 576,
  tablet: 768,
  smallScreen: 1024,
  edgeCaseCollapse: 1140,
  mediumScreen: 1220,
  largeScreen: 1440,
  fullScreen: 1680,
};

export const breakpoints = {
  mobile: `(min-width: ${rem(deviceSizes.mobile)})`,
  tablet: `(min-width: ${rem(deviceSizes.tablet)})`,
  smallScreen: `(min-width: ${rem(deviceSizes.smallScreen)})`,
  mediumScreen: `(min-width: ${rem(deviceSizes.mediumScreen)})`,
  largeScreen: `(min-width: ${rem(deviceSizes.largeScreen)})`,
  fullScreen: `(min-width: ${rem(deviceSizes.fullScreen)})`,
};

export default {
  colors,
  deviceSizes,
  breakpoints,
  transitions,
  globalSize,
  variables,
  colorName,
  layers,
  font,
};
