import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  resolveDashboardRoute,
  resolveDocumentsRoute,
  resolveMisconductsRoute,
  ROUTING,
} from 'routing';

import MainMenuItem from './MainMenuItem';
import DashboardIcon from 'components/Svg/DashboardIcon';
import DocumentsIcon from 'components/Svg/DocumentsIcon';
import ReportIcon from 'components/Svg/ReportIcon';
import UsersIcon from 'components/Svg/UsersIcon';
import GroupIcon from 'components/Svg/GroupIcon';
import MisconbotIcon from 'components/Svg/MisconbotIcon';

import { MainMenu, BurgerWrapper, BurgerMenu, MainMenuWrapper } from '../style';
import { messages } from '../messages';
import { useCustomConfig } from 'helpers/hooks';
import SearchIcon from 'components/Svg/SearchIcon';
import RaisedEmployeeLogin from './ExtendedEmployeeLogin';
import ManageDocumentIcon from 'components/Svg/ManageDocumentIcon';
import {
  DOCUMENTS_MODULE,
  isAuthenticatedEmployee,
  isAuthenticatedOfficer,
  MISCONDUCTS_MODULE,
} from '../../../stores/auth';
import ProtectedSection from '../../RolesManagement/ProtectedSetion';

const SideMenu = () => {
  const intl = useIntl();
  const auth = useSelector((state) => state.auth);
  const { hasOwnedMisconducts } = auth;
  const {
    properties: { aiChat },
  } = useSelector((state) => state.tenantProperties);

  const hasOfficerRights = isAuthenticatedOfficer(auth);
  const hasExtendedEmployeeRights = isAuthenticatedEmployee(auth);

  const [isMenuOpen, triggerMenu] = useState(false);
  const dashboardRoute = resolveDashboardRoute(hasOfficerRights);
  const documentsRoute = resolveDocumentsRoute(hasOfficerRights);
  const misconductsRoute = resolveMisconductsRoute(hasOfficerRights);
  const { changeWhistleWithLoupe } = useCustomConfig();

  return (
    <MainMenu isOpen={isMenuOpen}>
      <BurgerWrapper onClick={() => triggerMenu((prevState) => !prevState)}>
        <BurgerMenu isOpen={isMenuOpen} />
      </BurgerWrapper>

      <MainMenuWrapper isOpen={isMenuOpen}>
        <MainMenuItem
          to={dashboardRoute}
          label={intl.formatMessage(messages.dashboard)}
          icon={<DashboardIcon />}
        />
        {!hasOfficerRights && (
          <ProtectedSection modules={[DOCUMENTS_MODULE]}>
            <MainMenuItem
              to={documentsRoute}
              label={intl.formatMessage(messages.documents)}
              icon={<DocumentsIcon />}
            />
          </ProtectedSection>
        )}
        <ProtectedSection modules={[MISCONDUCTS_MODULE]}>
          {hasOwnedMisconducts && hasExtendedEmployeeRights && !hasOfficerRights && (
            <MainMenuItem
              to={misconductsRoute}
              label={intl.formatMessage(messages.reports)}
              icon={!changeWhistleWithLoupe ? <ReportIcon /> : <SearchIcon />}
            />
          )}
        </ProtectedSection>
        {/*<MainMenuItem*/}
        {/*  to={ROUTING.TRAININGS}*/}
        {/*  label={intl.formatMessage(messages.trainings)}*/}
        {/*  icon={<TrainingsIcon />}*/}
        {/*/>*/}
        {hasOfficerRights && (
          <>
            <ProtectedSection modules={[DOCUMENTS_MODULE]} as={'officer'}>
              <MainMenuItem
                to={documentsRoute}
                label={intl.formatMessage(messages.documents)}
                icon={<DocumentsIcon />}
              />
            </ProtectedSection>
            <ProtectedSection modules={[MISCONDUCTS_MODULE]}>
              <MainMenuItem
                to={misconductsRoute}
                label={intl.formatMessage(messages.reports)}
                icon={!changeWhistleWithLoupe ? <ReportIcon /> : <SearchIcon />}
              />
            </ProtectedSection>

            <ProtectedSection modules={[DOCUMENTS_MODULE, MISCONDUCTS_MODULE]}>
              <MainMenuItem
                to={ROUTING.USER_MANAGEMENT}
                label={intl.formatMessage(messages.users)}
                icon={<UsersIcon />}
              />
            </ProtectedSection>

            {aiChat ? (
              <ProtectedSection modules={[MISCONDUCTS_MODULE]}>
                <MainMenuItem
                  to={ROUTING.MISCON_CHATS_HISTORY}
                  label={intl.formatMessage(messages.misconbot)}
                  icon={<MisconbotIcon />}
                />
              </ProtectedSection>
            ) : null}

            <ProtectedSection modules={[DOCUMENTS_MODULE, MISCONDUCTS_MODULE]}>
              <MainMenuItem
                to={ROUTING.GROUPS}
                label={intl.formatMessage(messages.groups)}
                icon={<GroupIcon />}
              />
            </ProtectedSection>

            <ProtectedSection modules={[DOCUMENTS_MODULE]} as={'officer'}>
              <MainMenuItem
                to={ROUTING.CATEGORIES}
                label={intl.formatMessage(messages.categories)}
                icon={<ManageDocumentIcon />}
              />
            </ProtectedSection>
          </>
        )}

        <RaisedEmployeeLogin isExpanded={isMenuOpen} />
      </MainMenuWrapper>
    </MainMenu>
  );
};

export default SideMenu;
