import { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSubdomain } from './functions';

export const useArray = (initialState) => {
  const [values, setValue] = useState(initialState);

  return {
    values,
    setValue,
    isInArray: (value) => {
      return values.indexOf(value) > -1;
    },
    add: useCallback((a) => setValue((v) => [...v, a]), []),
    clear: useCallback(() => setValue(() => []), []),
    remove: useCallback(
      (filteredValue) => setValue((arr) => arr.filter((v) => v !== filteredValue)),
      []
    ),
  };
};

export const usePaginationControls = ({
  perPage,
  totalPages,
  dataUpdater,
  sortParams,
}) => {
  const onPageChange = useCallback(
    (e) => {
      dataUpdater({ page: parseInt(e), perPage, totalPages, sortParams });
    },
    [dataUpdater, totalPages, perPage, sortParams]
  );

  const onPerPageChange = useCallback(
    (e) => {
      dataUpdater({ page: 1, perPage: parseInt(e), totalPages, sortParams });
    },
    [totalPages, dataUpdater, sortParams]
  );

  const onSortParamsChange = useCallback(
    (sortParams) => {
      dataUpdater({ page: 1, perPage, totalPages, sortParams });
    },
    [perPage, totalPages, dataUpdater]
  );

  return {
    onPageChange,
    onPerPageChange,
    onSortParamsChange,
  };
};

export const usePaginatedList = ({ page, perPage, sortParams, dataUpdater }) => {
  useEffect(() => {
    dataUpdater({ page, perPage, sortParams });
  }, [dataUpdater, page, perPage, sortParams]);
};

export const useProgressingNumber = ({
  start = 0,
  target,
  duration = 800,
  frameCount = 20,
}) => {
  const [val, setVal] = useState(start);
  const step = Math.ceil(target / frameCount);
  const frameLength = Math.round(duration / frameCount);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      if (val + step >= target) {
        setVal(target);
      } else {
        setVal(Math.round(val + step));
      }
    }, frameLength);

    return () => {
      clearInterval(interval);
    };
  }, [val, setVal, target, frameLength, step]);

  return val;
};

export const useDebounce = (value, delay, callback) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, callback]);
};

export const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useFetchFileUrl = (fileUrlResolver, id, misconductId) => {
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    let unmounted = false;

    async function fetchFileUrl() {
      const isIdSupplied = misconductId !== undefined;
      const resolvedFileUrl = isIdSupplied
        ? await fileUrlResolver(id, misconductId)
        : await fileUrlResolver(id);

      if (!unmounted) {
        setFileUrl(isIdSupplied ? resolvedFileUrl.data : resolvedFileUrl.data);
      }
    }

    fileUrlResolver && id && fetchFileUrl();
    return () => (unmounted = true);
  }, [fileUrlResolver, id, misconductId]);

  return fileUrl;
};

export const useSearchQuery = (dataUpdater) => {
  const [searchQuery, setSearchQuery] = useState('');

  const dataUpdaterWithSearchQuery = useCallback(
    (params) => {
      dataUpdater({ ...params, query: searchQuery });
    },
    [dataUpdater, searchQuery]
  );

  const searchActive = searchQuery !== undefined && searchQuery !== '';

  return [dataUpdaterWithSearchQuery, searchActive, setSearchQuery];
};

export const useFiltersQuery = (dataUpdater) => {
  const [filters, setFilters] = useState([]);

  const dataUpdaterWithFilters = useCallback(
    (params) => dataUpdater({ ...params, filters }),
    [dataUpdater, filters]
  );

  return [dataUpdaterWithFilters, setFilters];
};

export const useExtendedSearchQuery = (dataUpdater, initialData) => {
  const [searchQuery, setSearchQuery] = useState(initialData);

  const dataUpdaterWithSearchQuery = useCallback(
    (params) => {
      dataUpdater({ ...params, query: searchQuery });
    },
    [dataUpdater, searchQuery]
  );

  // const searchActive = searchQuery !== undefined && searchQuery !== '';

  return [dataUpdaterWithSearchQuery, setSearchQuery];
};

export const useExtendedFiltersQuery = (dataUpdater, initalData) => {
  const [filters, setFilters] = useState(initalData);

  const dataUpdaterWithFilters = useCallback(
    (params) => {
      dataUpdater({ ...params, filters });
    },
    [dataUpdater, filters]
  );

  return [dataUpdaterWithFilters, setFilters];
};

export const useCustomConfig = () => {
  const {
    properties: { customConfig },
  } = useSelector((state) => state.tenantProperties);

  return {
    languagesFromMisconducts:
      customConfig &&
      customConfig['languagesFromMisconducts'] &&
      customConfig['languagesFromMisconducts'].includes(getSubdomain()),
    hideCheckMisconductStatus:
      customConfig &&
      customConfig['hideCheckMisconductStatus'] &&
      customConfig['hideCheckMisconductStatus'].includes(getSubdomain()),
    changeWhistleWithLoupe:
      customConfig &&
      customConfig['changeWhistleWithLoupe'] &&
      customConfig['changeWhistleWithLoupe'].includes(getSubdomain()),
  };
};

// export const useListQueryParams = () => {
//   const [query, setQuery] = useQueryParams({
//     search: withDefault(StringParam, ''),
//     filters: withDefault(JsonParam, {
//       priority: [],
//       status: [],
//       date_created: '',
//     }),
//   });

//   const setSearchValue = useCallback(
//     (newValue) => {
//       setQuery((prev) => {
//         console.log(prev);
//         // dataUpdater({ ...prev, query: newValue });
//         return {
//           ...prev,
//           search: newValue,
//         };
//       }, 'replaceIn');
//     },
//     [setQuery]
//   );

//   const setFilterValues = useCallback(
//     (dataUpdater, newValue) => {

//       setQuery((prev) => {
//         console.log({
//           ...prev,
//           filters: {
//             ...prev.filters,
//             ...newValue,
//           },
//         });
//         dataUpdater({
//           ...prev,
//           filters: {
//             ...prev.filters,
//             ...newValue,
//           },
//         });
//         return {
//           ...prev,
//           filters: {
//             ...prev.filters,
//             ...newValue,
//           },
//         };
//       }, 'replaceIn');
//     },
//     [setQuery]
//   );

//   return {
//     searchValue: query.search,
//     setSearchValue,
//     filterValues: query.filters,
//     setFilterValues,
//   };
// };

export const useAutosizeTextArea = (textAreaRef, value, addition = 0) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight + addition + 'px';
    }
  }, [textAreaRef, value, addition]);
};

export function useCopyToClipboard(text) {
  const [isCopied, setIsCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const copy = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      //eslint-disable-next-line no-console
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      //eslint-disable-next-line no-console
      console.warn('Copy failed', error);
      return false;
    }
  }, []);

  const handleCopy = useCallback(async () => {
    await copy(text);
    setIsCopied(true);
    setTimeoutId(setTimeout(() => setIsCopied(false), 2000));
  }, [text, copy]);

  useEffect(() => {
    if (!timeoutId) return;
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return { copy: handleCopy, isCopied };
}
