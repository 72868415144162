import React from 'react';
import PropTypes from 'prop-types';
import { avatarSize } from 'components/UserAvatar';

import {
  MessageWrapper,
  ContentWrapper,
  DotWrapper,
  Dot,
  MessageMarkdownWrapper,
} from '../style';
import AIAvatar from 'components/AIAvatar';
import { addReferenceToLinks, MessageMarkdown } from './MessageMarkdown';
import { EmbedLinksList } from './MessageLinks';

const propTypes = {
  isOutcoming: PropTypes.bool,
  message: PropTypes.string,
  isDark: PropTypes.bool,
};

const TypingDotsMessage = ({ isOutcoming, message, isDark }) => {
  const { message: finalMessage, links } = addReferenceToLinks(message);
  return (
    <MessageWrapper isOutcoming={isOutcoming}>
      <AIAvatar size={avatarSize.MEDIUM} />

      <ContentWrapper isOutcoming={isOutcoming}>
        {finalMessage && (
          <MessageMarkdownWrapper isOutcoming={isOutcoming} isDark={isDark}>
            <MessageMarkdown>{finalMessage}</MessageMarkdown>

            <EmbedLinksList links={links} />
          </MessageMarkdownWrapper>
        )}
        <DotWrapper>
          <Dot delay="0s" />
          <Dot delay=".1s" />
          <Dot delay=".2s" />
        </DotWrapper>
      </ContentWrapper>
    </MessageWrapper>
  );
};

TypingDotsMessage.propTypes = propTypes;

export default TypingDotsMessage;
