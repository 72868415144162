// Actions

import { uniqueById } from '../helpers/functions';
import api from 'api';
export const SET_MISCONBOT_DETAILS = 'misconbot.SET_MISCONBOT_DETAILS';
export const SET_MISCONBOT_GENERATING = 'misconbot.SET_MISCONBOT_GENERATING';
export const SET_MISCONBOT_IDLE = 'misconbot.SET_MISCONBOT_IDLE';
export const SET_STREAMING_MESSAGE = 'misconbot.SET_STREAMING_MESSAGE';
export const SET_HISTORY_LOADING = 'misconbot.SET_HISTORY_LOADING';
export const SET_HISTORY_LOADED = 'misconbot.SET_HISTORY_LOADED';
export const REFRESH_HISTORY = 'misconbot.REFRESH_HISTORY';
export const RESET_CURRENT_CHAT = 'misconbot.RESET_CURRENT_CHAT';
export const SET_CHAT_MESSAGES = 'misconbot.SET_CHAT_MESSAGES';
export const LOAD_MORE_CHAT_MESSAGES = 'misconbot.LOAD_MORE_CHAT_MESSAGES';

export const UPDATE_CHAT_DETAILS = 'misconbot.UPDATE_CHAT_DETAILS';

export const MISCON_CONNECT = 'misconbot.MISCON_CONNECT';
export const MISCON_CONNECT_ERROR = 'misconbot.MISCON_CONNECT_ERROR';
export const MISCON_DISCONNECT = 'misconbot.MISCON_DISCONNECT';

export const MISCON_CONNECTED = 'misconbot.MISCON_CONNECTED';
export const MISCON_DISCONNECTED = 'misconbot.MISCON_DISCONNECTED';

// Reducer
const initialState = {
  chats: {
    current: null,
  },
  messages: {}, // {[id]: []}
  streamingMessage: '',
  streamingStatus: 'idle', //"idle" | "generating"
  historyStatus: 'loading', //"idle" | "loading" | "loaded"
  details: {
    connectStatus: 'idle', //"idle" | "connecting" | "connected" | 'error',
    run_id: null,
    workflow_id: null,
    organization_id: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHAT_MESSAGES:
      // eslint-disable-next-line no-case-declarations
      let messages = [];

      if (state.messages[action.payload.data.id]) {
        messages = uniqueById([
          ...state.messages[action.payload.data.id],
          ...action.payload.data.messages,
        ]);
      } else {
        messages = action.payload.data.messages;
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.data.id]: messages,
        },
      };

    case LOAD_MORE_CHAT_MESSAGES:
      // eslint-disable-next-line no-case-declarations
      let updatedMessages = [];

      if (state.messages[action.payload.data.id]) {
        updatedMessages = uniqueById([
          ...action.payload.data.messages,
          ...state.messages[action.payload.data.id],
        ]);
      } else {
        updatedMessages = action.payload.data.messages;
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.data.id]: updatedMessages,
        },
      };

    case UPDATE_CHAT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      let updatedChats = {
        ...state.chats,
        [action.payload.data.id]: action.payload.data,
      };

      if (state.chats.current && state.chats.current.id === action.payload.data.id) {
        updatedChats.current = action.payload.data;
      }

      return {
        ...state,
        chats: updatedChats,
      };
    case SET_MISCONBOT_GENERATING:
      return {
        ...state,
        streamingStatus: 'generating',
      };
    case SET_MISCONBOT_IDLE:
      return {
        ...state,
        streamingMessage: '',
        streamingStatus: 'idle',
      };
    case SET_STREAMING_MESSAGE:
      return {
        ...state,
        streamingMessage: state.streamingMessage + action.payload.data,
      };
    case SET_HISTORY_LOADING:
      return {
        ...state,
        historyStatus: 'loading',
      };
    case SET_HISTORY_LOADED:
      return {
        ...state,
        chats: action.payload.data.reduce((acc, chat) => {
          acc[chat.id] = chat;
          return acc;
        }, {}),
        historyStatus: 'loaded',
      };
    case REFRESH_HISTORY:
      return {
        ...state,
        chats: {
          ...action.payload.data.reduce((acc, chat) => {
            acc[chat.id] = chat;
            return acc;
          }, {}),
          current: state.chats.current,
        },
        historyStatus: 'loaded',
      };
    case MISCON_CONNECT:
      return {
        ...state,
        streamingMessage: '',
        streamingStatus: 'idle',
        chats: {
          ...state.chats,
          current: action.payload.data,
          [action.payload.data.id]: action.payload.data,
        },
        details: {
          ...state.details,
          ...action.payload.data.ai_chat_topic,
          connectStatus: 'connecting',
        },
      };
    case MISCON_CONNECTED:
      return {
        ...state,
        details: {
          ...state.details,
          connectStatus: 'connected',
        },
      };
    case MISCON_CONNECT_ERROR:
      return {
        ...state,
        details: {
          ...state.details,
          connectStatus: 'error',
        },
      };
    case MISCON_DISCONNECTED:
      return {
        ...state,
        details: {
          ...state.details,
          isConnected: 'idle',
        },
      };
    case RESET_CURRENT_CHAT:
      return initialState;
    default:
      return state;
  }
}

// Action Creators

export function setMisconbotDetails(data) {
  return { type: SET_MISCONBOT_DETAILS, payload: { data } };
}

export function setMisconbotGenerating() {
  return { type: SET_MISCONBOT_GENERATING };
}

export function setMisconbotIdle() {
  return { type: SET_MISCONBOT_IDLE };
}

export function setStreamingMessage(data) {
  return { type: SET_STREAMING_MESSAGE, payload: { data } };
}

export function setChatMessages(data) {
  return { type: SET_CHAT_MESSAGES, payload: { data } };
}

export function loadMoreMessages(data) {
  return { type: LOAD_MORE_CHAT_MESSAGES, payload: { data } };
}

export function setHistoryLoading() {
  return { type: SET_HISTORY_LOADING };
}

export function setHistoryLoaded(data) {
  return { type: SET_HISTORY_LOADED, payload: { data } };
}

export function refreshChatsHistory(data) {
  return { type: REFRESH_HISTORY, payload: { data } };
}

export function misconConnect(data, onConnect = () => null) {
  return { type: MISCON_CONNECT, payload: { data, onConnect } };
}

export function misconDisconnect() {
  return { type: MISCON_DISCONNECT };
}

export function misconConnectError() {
  return { type: MISCON_CONNECT_ERROR };
}

export function resetCurrentChat() {
  return { type: RESET_CURRENT_CHAT };
}

export function misconConnected() {
  return { type: MISCON_CONNECTED };
}

export function misconDisconnected() {
  return { type: MISCON_DISCONNECTED };
}

export function updateChatDetails(data) {
  return { type: UPDATE_CHAT_DETAILS, payload: { data } };
}

export function disconnectAndResetCurrentChat() {
  return (dispatch) => {
    dispatch(misconDisconnect());
    dispatch(resetCurrentChat());
  };
}

export function getChatMessages(id) {
  return (dispatch) => {
    return api.misconbot
      .getMessages(id)
      .then((res) => {
        dispatch(setChatMessages({ id, messages: res.data }));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function loadMoreChatMessages(
  onLoad = () => {},
  onLoaded = () => {},
  onNoMore = () => {}
) {
  return (dispatch, getState) => {
    const { chats, messages } = getState().misconbot;

    if (!chats.current || !messages[chats.current.id]) return;

    onLoad();

    return api.misconbot
      .getMessages(chats.current.id, { before: messages[chats.current.id][0].id })
      .then((res) => {
        dispatch(loadMoreMessages({ id: chats.current.id, messages: res.data }));

        if (res.data.length === 0) {
          onNoMore();
        }
      })
      .catch(() => {
        // @todo: Add Error handling
      })
      .finally(() => {
        onLoaded();
      });
  };
}

export function getSingleChat(id) {
  return (dispatch) => {
    return api.misconbot
      .getChat(id)
      .then((res) => {
        dispatch(updateChatDetails(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getMessagesAndStopStreaming(id) {
  return (dispatch) => {
    return api.misconbot
      .getMessages(id)
      .then((res) => {
        dispatch(setChatMessages({ id, messages: res.data }));
        dispatch(setMisconbotIdle());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getChatsHistory() {
  return (dispatch) => {
    dispatch(setHistoryLoading());

    return api.misconbot
      .getChats()
      .then((res) => {
        dispatch(setHistoryLoaded(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function refreshHistory() {
  return (dispatch) => {
    return api.misconbot
      .getChats()
      .then((res) => {
        dispatch(refreshChatsHistory(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function createNewConversation(message, context, onCreate = () => null) {
  return (dispatch) => {
    return api.misconbot
      .createConversation(context)
      .then((res) => {
        dispatch(
          misconConnect(res.data, () => {
            if (message) {
              dispatch(sendMessageToChat(message));
            }
          })
        );

        dispatch(refreshHistory());

        onCreate();
      })
      .catch((err) => {
        //eslint-disable-next-line no-console
        console.error(err);
      });
  };
}

export function sendMessageToChat(text) {
  return (dispatch, getState) => {
    const state = getState().misconbot;

    return api.misconbot
      .sendMessage(state.chats.current.id, text)
      .then(() => {
        dispatch(getChatMessages(state.chats.current.id));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function buildReportsContext() {
  return 'reports';
}

export function buildReportContext(id) {
  return `${buildReportsContext()}/${id}`;
}
