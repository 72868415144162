import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  EmbedLinksHeading,
  EmbedLinksWrapper,
  EmbedList,
  EmbedListItem,
  EmbedListItemHostname,
  EmbedListItemIdx,
  EmbedListItemLink,
} from '../style';

const embedLinksListPropTypes = {
  links: PropTypes.array,
};

export function EmbedLinksList({ links }) {
  const listRef = useRef(null);

  if (links.length === 0) return null;

  return (
    <EmbedLinksWrapper>
      <EmbedLinksHeading>See more</EmbedLinksHeading>
      <EmbedList ref={listRef}>
        {links.map((link, idx) => (
          <EmbedListItem key={idx}>
            <EmbedListItemLink href={link.toString()} target="_blank" rel="noreferrer">
              <EmbedListItemIdx>{idx + 1}</EmbedListItemIdx>
              <EmbedListItemHostname>{link.hostname}</EmbedListItemHostname>
            </EmbedListItemLink>
          </EmbedListItem>
        ))}
      </EmbedList>
    </EmbedLinksWrapper>
  );
}

EmbedLinksList.propTypes = embedLinksListPropTypes;
