import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dashboard: {
    id: 'SideMenu.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Dashboard Label',
  },
  documents: {
    id: 'SideMenu.documents',
    defaultMessage: 'Documents',
    description: 'Documents Label',
  },
  categories: {
    id: 'SideMenu.categories',
    defaultMessage: 'Categories',
    description: 'Categories Label',
  },
  trainings: {
    id: 'SideMenu.trainings',
    defaultMessage: 'Trainings',
    description: 'Trainings Label',
  },
  reports: {
    id: 'SideMenu.reports',
    defaultMessage: 'Reports',
    description: 'Reports Label',
  },
  misconbot: {
    id: 'SideMenu.misconbot',
    defaultMessage: 'AI Assistant',
    description: 'AI Assistant Label',
  },
  users: {
    id: 'SideMenu.users',
    defaultMessage: 'Users',
    description: 'Users Label',
  },
  groups: {
    id: 'SideMenu.groups',
    defaultMessage: 'Groups',
    description: 'Groups Label',
  },
  sessionTimeout: {
    id: 'TopMenu.sessionTimeout',
    defaultMessage: 'Session timeout',
    description: 'Session timeout label',
  },
  reportMisconduct: {
    id: 'TopMenu.reportMisconduct',
    defaultMessage: 'Report misconduct',
    description: 'Report Misconduct Button label',
  },
  logOut: {
    id: 'TopMenu.logOut',
    defaultMessage: 'Log Out',
    description: 'Log out Button label',
  },
  changePassword: {
    id: 'TopMenu.changePassword',
    defaultMessage: 'Change Password',
    description: 'Change password Button label',
  },
  misconductModalTitle: {
    id: 'MenuModal.misconductModalTitle',
    defaultMessage: 'Submitting a report is completely anonymous',
    description: 'Modal Title',
  },
  misconductModalEmphasis: {
    id: 'MenuModal.misconductModalEmphasis',
    defaultMessage: 'We will log you out',
    description: 'Modal Title',
  },
  misconductModalDesc: {
    id: 'MenuModal.misconductModalDesc',
    defaultMessage: '{emphasis} from your account and redirect to the anonymous form.',
    description: 'Modal Title',
  },
  employeeView: {
    id: 'MenuModal.employeeView',
    defaultMessage: 'User view',
    description: 'Employee button label',
  },
  extendedEmployeeView: {
    id: 'MenuModal.extendedEmployeeView',
    defaultMessage: 'Extended user view',
    description: 'Extended employee button label',
  },
  officerView: {
    id: 'MenuModal.officerView',
    defaultMessage: 'Compliance Officer view',
    description: 'Officer button label',
  },
  officerLoginHeader: {
    id: 'MenuModal.officerLoginHeader',
    defaultMessage: 'Logging into Compliance Officer view',
    description: 'Officer Login Header',
  },
  extendedEmployeeLoginHeader: {
    id: 'MenuModal.extendedEmployeeLoginHeader',
    defaultMessage: 'Logging into extended user view',
    description: 'Extended user Login Header',
  },
  officerLoginText: {
    id: 'MenuModal.officerLoginText',
    defaultMessage:
      'Please enter your password to access your Compliance Officer privileges.',
    description: 'Officer Login Text',
  },
  extendedEmployeeLoginText: {
    id: 'MenuModal.extendedEmployeeLoginText',
    defaultMessage: 'Please enter your password to access your extended user privileges.',
    description: 'Extended employee Login Text',
  },
  officerTwoFactorLoginText: {
    id: 'MenuModal.officerTwoFactorLoginText',
    defaultMessage:
      'Click the button below to send the aunthentication code to your email address. Then log in with your code. Please note that the code is only valid for a few minutes.',
    description: 'Officer Two Factor Login Text',
  },
  loggedAs: {
    id: 'MenuModal.loggedAs',
    defaultMessage: 'Logged in as',
    description: 'Officer box title to login',
  },
  sessionTimeoutModalHeader: {
    id: 'SessionModal.sessionTimeoutModalHeader',
    defaultMessage: 'Your session is about to expire',
    description: 'Session modal header',
  },
  sessionTimeoutModalDescription: {
    id: 'SessionModal.sessionTimeoutModalDescription',
    defaultMessage: 'Please make any action to keep your session active',
    description: 'Session modal description',
  },
  authenticationCode: {
    id: 'MenuModal.authenticationCode',
    defaultMessage: 'Authentication code',
    description: 'Authentication code label',
  },
  code: {
    id: 'MenuModal.code',
    defaultMessage: 'Code',
    description: 'Code input placeholder',
  },
  sendCode: {
    id: 'MenuModal.sendCode',
    defaultMessage: 'Send authentication code',
    description: 'Send authentication code button label',
  },
  resendCode: {
    id: 'MenuModal.resendCode',
    defaultMessage: 'Resend authentication code',
    description: 'Resend authentication code button label',
  },
});
